const envDev = 'qcero'; // local or qcero
const envUrlData = 'internal'; // dummy or qcero

const env = {
    local: {
        url: 'http://127.0.0.1:8000/api/v1/',
        defaultColor: 'light.greenmoss',
    },
    localred: {
        url: 'http://192.168.128.16:8000/api/v1/',
        defaultColor: 'light.greenmoss',
    },
    qcero: {
        url: 'https://api.planti.cloud/api/v1/',
        defaultColor: 'light.greenmoss',
    }
}
export const versionApp = '1.5.0';
//******* URL DATA ****************/
const envData = {
    dummy: {
        url: `${env[envDev].url}dummy`,
    },
    internal: {
        url: `${env[envDev].url}dVkzeFNXYnFpSnF1VmI4/real`,
    }
}
//******* END URL DATA ************/
export const apiUrl = env[envDev].url;
export const urlData = envData[envUrlData].url;
export const headers = {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
}
//*****design********* 
export let lightTheme = {
    base: '#0D3531',  // Color principal de fondo
    primary: '#0D3531',  // Color principal
    secondary: '#FFFFFF',  // Color secundario
    accent: '#d8d9d4',  // Color de acento
    error: '#ff0000',  // Color para errores
    foot: '#254945',  // Color del pie de página
    anchor: '#8c9eff',  // Color de los enlaces
    textcolor: '#000000',
    drawer_color: '#ffffff'
};

export let darkTheme = {
    base: '#000000',
    primary: '#000000',
    secondary: '#000000',
    accent: '#BAE310',
    error: '#ff0000',
    foot: '#000000',
    textcolor: '#ffffff',
    drawer_color: '#ffffff'
};


export let defaultDarkTheme = false;
export const elevation = 1;
